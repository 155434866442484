import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide58/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide58/image/img.png"
import slider1 from "@components/pageGuide/guides/guide58/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide58/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide58/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
    10 formas de mejorar la atención digital en el sector farmacéutico  
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
    El sector farmacéutico enfrenta un desafío creciente para adaptarse a las demandas de un mundo digitalizado. La atención al cliente digital es más importante que nunca, ya que los consumidores buscan comodidad, rapidez y personalización. 
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
Como gerente o decisor en áreas de atención al cliente, marketing o telemarketing, sabes que mejorar la atención digital puede marcar la diferencia en la satisfacción y lealtad de tus clientes.   
<br /><br />
En esta guía, te presentamos 10 formas efectivas de mejorar la atención digital en el sector farmacéutico, asegurando que puedas ofrecer una experiencia de alta calidad que cumpla con las expectativas de tus clientes. 
 </p>
)

const data = {
  start: {
    support: "Guía | Salud ",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Implementa un autoservicio con chatbots.",
      },
      {
        key: 2,
        text: " Notificaciones del envío de productos.",
      },
      {
        key: 3,
        text: "Permite la validación de receta médica por chat.",
      },
      {
        key: 4,
        text: "Envía ofertas personalizadas por WhatsApp.",
      },
      {
        key: 5,
        text: "Gestión de sistemas de puntos.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
